import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, Divider } from '@mui/material';
import { AppShortcutOutlined, ArrowBackOutlined, CalendarMonth, Check, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Horarios() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";
  const [segment, setSegment] = useState("Todos");

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/escolher-profissional');
  };

  const handleSegment = () => {

  };

  const handlePagamento = () => {
    navigate('/forma-pagamento');
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Horários
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Escolha seu horário
        </Typography>

        <div style={{ height: "20px" }}></div>

        <Typography style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "-5px" }}>
          Terça, 29 abril
        </Typography>

        <Chip variant={segment === "Todos" ? "filled" : "outlined"} label="09:00" icon={segment === "Todos" ? <Check style={{ color: "#FFF" }} /> : ""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: segment === "Todos" ? "#1B1B1B" : "", color: segment === "Todos" ? "#FFF" : "#1B1B1B" }} onClick={() => handleSegment("Todos")} />
        <Chip variant={"outlined"} label={"10:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"11:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"12:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"13:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"14:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"15:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"16:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"17:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"18:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />

        <div style={{ height: "20px" }}></div>
        <Divider />

        <div style={{ height: "20px" }}></div>
        <Typography style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "-5px" }}>
          Terça, 29 abril
        </Typography>

        <Chip variant={"outlined"} label={"10:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"11:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"12:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"13:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"14:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"15:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"16:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"17:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"18:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />

        <div style={{ height: "20px" }}></div>
        <Divider />

        <div style={{ height: "20px" }}></div>
        <Typography style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "-5px" }}>
          Terça, 29 abril
        </Typography>

        <Chip variant={"outlined"} label={"10:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"11:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"12:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"13:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"14:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"15:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"16:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"17:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"18:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />

        <div style={{ height: "20px" }}></div>
        <Divider />

        <div style={{ height: "130px" }}></div>


        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "25px 16px 25px 16px" }} onClick={handlePagamento}>
          <Button variant="contained" size="large" fullWidth style={{ fontWeight: "bold", textTransform: "capitalize" }}>
            Continuar
          </Button>
        </div>

      </Container>
    </ThemeProvider>
  );
}

export default Horarios;