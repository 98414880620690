import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, Divider } from '@mui/material';
import { AppShortcutOutlined, ArrowBackOutlined, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

function FinalizarAgendamento() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/forma-pagamento');
  };

  const handleFinalizar = () => {
    navigate('/pedido-finalizado');
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Finalizar
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Finalize seu agendamento
        </Typography>

        <div style={{ height: "20px" }}></div>

        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component="div" style={{ textAlign: "center", fontWeight: "bold", paddingLeft: 10 }}>
              Terça, 10 de abril
            </Typography>
          </Box>
          <Typography style={{ position: "relative", marginLeft: "auto", backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", padding: 10 }}>
            10:00
          </Typography>
        </Card>

        <div style={{ height: "20px" }}></div>

        <Divider />

        <div style={{ height: "10px" }}></div>
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
          Mais detalhes
        </Typography>

        <div style={{ height: "5px" }}></div>

        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ position: "relative", backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", padding: "10px 40px 10px 40px" }}>
            Serviço
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <Typography component="div" style={{ textAlign: "center", fontWeight: "bold", paddingLeft: 10 }}>
              Corte de Cabelo
            </Typography>
          </Box>
        </Card>

        <div style={{ height: "20px" }}></div>

        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ position: "relative", backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", padding: "10px 23.5px 10px 23.5px" }}>
            Profissional
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <Typography component="div" style={{ textAlign: "center", fontWeight: "bold", paddingLeft: 10 }}>
              Corte de Cabelo
            </Typography>
          </Box>
        </Card>

        <div style={{ height: "20px" }}></div>

        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ position: "relative", backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", padding: "10px 25px 10px 25px" }}>
            Pagamento
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <Typography component="div" style={{ textAlign: "center", fontWeight: "bold", paddingLeft: 10 }}>
              Corte de Cabelo
            </Typography>
          </Box>
        </Card>

        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "25px 16px 25px 16px" }}>
          <Button fullWidth variant="contained" size="large" style={{ textTransform: "capitalize", fontWeight: "700" }} onClick={() => handleFinalizar()}>
            Continuar
          </Button>
        </div>
        <div style={{ height: "130px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default FinalizarAgendamento;