import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton } from '@mui/material';
import { AccessTimeOutlined, ArrowBackOutlined, CodeOutlined, InfoOutlined, LocalPhoneOutlined, LocationOnOutlined } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';

import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import '@fontsource/roboto/400.css';

function Informacoes() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/")
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
            <ArrowBackOutlined />
          </IconButton>
          Informações
        </Toolbar>
      </AppBar>

      <div style={{ height: "70px" }}></div>

      <List sx={{ width: '100%', bgcolor: 'background.paper', margin: "auto" }}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <InfoOutlined style={{ color: "#1B1B1B" }} />
          </ListItemAvatar>
          <ListItemText
            primary="Sobre nós"
            primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
            secondary={
              <React.Fragment>
                <Typography style={{ fontSize: "14px", color: "#999" }}>
                  Descrição sobre nos exemplo
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <LocationOnOutlined style={{ color: "#1B1B1B" }} />
          </ListItemAvatar>
          <ListItemText
            primary="Endereço"
            primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
            secondaryTypographyProps={{ color: "#999999", fontSize: "14px" }}
            secondary={
              <React.Fragment>
                <Typography style={{ fontSize: "14px", color: "#999" }}>
                  Endereço exemplo
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <AccessTimeOutlined style={{ color: "#1B1B1B" }} />
          </ListItemAvatar>
          <ListItemText
            primary="Horário"
            primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
            secondaryTypographyProps={{ color: "#999999", fontSize: "14px" }}
            secondary={
              <React.Fragment>
                <Typography style={{ fontSize: "14px", color: "#999" }}>
                  Segunda: 00:00 às 00:00
                  Terça: 00:00 às 00:00
                  Quarta: 00:00 às 00:00
                  Quinta: 00:00 às 00:00
                  Sexta: 00:00 às 00:00
                  Sabado: 00:00 às 00:00
                  Domingo: 00:00 às 00:00
                  Feriados: 00:00 às 00:00
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <LocalPhoneOutlined style={{ color: "#1B1B1B" }} />
          </ListItemAvatar>
          <ListItemText
            primary="Telefone"
            primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
            secondaryTypographyProps={{ color: "#999999", fontSize: "14px" }}
            secondary={
              <React.Fragment>
                <Typography style={{ fontSize: "14px", color: "#999" }}>
                  Telefone 1: (11) 98765-4321
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <CodeOutlined style={{ color: "#1B1B1B" }} />
          </ListItemAvatar>
          <ListItemText
            primary="Sobre nós"
            primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
            secondaryTypographyProps={{ color: "#999999", fontSize: "14px" }}
            secondary={
              <React.Fragment>
                <Typography style={{ fontSize: "14px", color: "#999" }}>
                  Aplicativo desenvolvido pela empresa VRJ<br /><br />
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>

      <div style={{ height: "20px" }}></div>


      <div style={{ position: "relative", bottom: "0px", right: "0", left: "0", padding: "12px 16px 16px 32px" }}>
        <Typography style={{ textAlign: "center", fontSize: "11px", fontWeight: "bold", color: `#1B1B1B`, paddingTop: "4px" }}>
          <b style={{ fontSize: "20px"}}>© VRJ</b><br />
          v2.0.0.1
        </Typography>
      </div>
    </ThemeProvider>
  );
}

export default Informacoes;