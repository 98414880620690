import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Informacoes from './pages/Informacoes';
import Perfil from './pages/Perfil';
import NovoAgendamento from './pages/NovoAgendamento';
import MeusAgendamentos from './pages/MeusAgendamentos';
import EscolherProfissional from './pages/EscolherProfissional';
import Horarios from './pages/Horarios';
import FormaPagamento from './pages/FormaPagamento';
import FinalizarAgendamento from './pages/FinalizarAgendamento';
import PedidoFinalizado from './pages/PedidoFinalizado';


const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="informacoes/" element={<Informacoes />} />
        <Route path="perfil/" element={<Perfil />} />
        <Route path="novo-agendamento/" element={<NovoAgendamento />} />
        <Route path="meus-agendamentos/" element={<MeusAgendamentos />} />
        <Route path="escolher-profissional/" element={<EscolherProfissional />} />
        <Route path="horarios/" element={<Horarios />} />
        <Route path="forma-pagamento/" element={<FormaPagamento />} />
        <Route path="finalizar-agendamento/" element={<FinalizarAgendamento />} />
        <Route path="pedido-finalizado/" element={<PedidoFinalizado />} />
      </Routes>
    </Router>
  );
};

export default App;
