import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab } from '@mui/material';
import { AppShortcutOutlined, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';


function PedidoFinalizado() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>

      <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
          <Typography style={{ textAlign: "center", fontSize: "14px", color: "#999", minWidth: "300px" }}>

            <div style={{ height: "10px" }}></div>

            <img src="https://images.emojiterra.com/google/noto-emoji/unicode-15.1/color/1024px/1f601.png" style={{ width: "90px" }} />

            <div style={{ height: "10px" }}></div>

            <Typography style={{ textAlign: "center", fontSize: "24px", color: "#1B1B1B", minWidth: "300px" }}>
              Pedido #20000 criado!
            </Typography>

                Seu pedido foi realizado com sucesso, agora é só aparecer no horario agendado!

            <div style={{ height: "20px" }}></div>

          </Typography>
        </Typography>
      </Container>
    </ThemeProvider>
  );
}

export default PedidoFinalizado;