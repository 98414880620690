import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab } from '@mui/material';
import { AppShortcutOutlined, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';

function Home() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const navigate = useNavigate();

  const handleNovoAgendamento = () => {
    navigate('/novo-agendamento');
  }

  const handleMeusAgendamentos = () => {
    navigate('/meus-agendamentos');
  }

  const handlePerfil = () => {
    navigate('/perfil');
  }

  const handleInformacoes = () => {
    navigate('/informacoes');
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>

        <CardMedia component="img" height="170" image={`https://escolasuperclass.com.br/wp-content/uploads/2021/01/Cabeleireiro-Feminino.jpg`} style={{ position: "absolute", top: "0", left: "0", right: "0" }} onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = "https://escolasuperclass.com.br/wp-content/uploads/2021/01/Cabeleireiro-Feminino.jpg";
        }} />

        <Fab size="small" variant="circular" color="primary" aria-label="add" style={{ height: "38px", width: "38px", position: "absolute", right: "10px", top: "10px" }}>
          <ShareOutlined style={{ fontSize: "22px", color: "#FFF" }} />
        </Fab>


        <div style={{ height: "100px" }}></div>

        <Avatar src={`https://w7.pngwing.com/pngs/576/202/png-transparent-salon-logo-salon-hairdressing-logo-material.png`} style={{ width: "120px", height: "120px", margin: "auto", border: "solid 10px white" }}/>

        <Typography sx={{ color: '#334155', textAlign: "center", fontWeight: "bold", fontSize: "24px" }}>Cabelereira Leila</Typography>

        <div style={{ height: "20px" }}></div>

        <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNovoAgendamento}
              sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
              startIcon={<EventNote style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
            >
              <div style={{ height: "10px" }}></div>
              <Typography variant="button" sx={{ color: 'white' }}>Novo agendamento</Typography>
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleMeusAgendamentos}
              sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
              startIcon={<CalendarMonth style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
            >
              <div style={{ height: "10px" }}></div>
              <Typography variant="button" sx={{ color: 'white' }}>Meus agendamentos</Typography>
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePerfil}
              sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
              startIcon={<PersonOutline style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
            >
              <div style={{ height: "10px" }}></div>
              <Typography variant="button" sx={{ color: 'white' }}>Perfil</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInformacoes}
              sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
              startIcon={<InfoOutlined style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
            >
              <div style={{ height: "10px" }}></div>
              <Typography variant="button" sx={{ color: 'white' }}>Informações</Typography>
            </Button>
          </Grid>
        </Grid>

      </Container>
    </ThemeProvider>
  );
}

export default Home;