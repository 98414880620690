import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, CardHeader } from '@mui/material';
import { AppShortcutOutlined, ArrowBackOutlined, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PaymentOutlined, PaymentsOutlined, PersonOutline, PixOutlined, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

function FormaPagamento() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/horarios');
  };

  const handleFinalizar = () => {
    navigate('/finalizar-agendamento');
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Forma de pagamento
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Escolha sua forma de pagamento
        </Typography>

        <div style={{ height: "20px" }}></div>

        <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: '1px solid #DCDBDB' }}>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            avatar={
              <PaymentOutlined style={{ color: "#464545" }} />
            }
            title="CARTÃO DE CRÉDITO"
            titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
          />
        </Card>

        <div style={{ height: "8px" }}></div>

        <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: '1px solid #DCDBDB' }}>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            avatar={
              <PaymentOutlined style={{ color: "#464545" }} />
            }
            title="CARTÃO DE DÉBITO"
            titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
          />
        </Card>

        <div style={{ height: "8px" }}></div>
        <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: '1px solid #DCDBDB' }}>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            avatar={
              <PaymentsOutlined style={{ color: "#464545" }} />
            }
            title="DINHEIRO"
            titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
          />
        </Card>

        <div style={{ height: "8px" }}></div>

        <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: '1px solid #DCDBDB' }}>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            avatar={
              <PixOutlined style={{ color: "#464545" }} />
            }
            title="PIX"
            titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
          />
        </Card>

        <div style={{ height: "130px" }}></div>

        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "25px 16px 25px 16px" }} onClick={handleFinalizar}>
          <Button variant="contained" size="large" fullWidth style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Continuar
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default FormaPagamento;