import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, Divider } from '@mui/material';
import { AppShortcutOutlined, ArrowBackOutlined, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';

function NovoAgendamento(props) {

  const { windows } = props;

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const container = windows !== undefined ? () => windows().document.body : undefined;

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleAgendar = () => {
    navigate('/escolher-profissional');
  };
  
  const handleBack = () => {
    navigate('/');
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Serviços
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Serviços disponíveis
        </Typography>

        <div style={{ height: "20px" }}></div>

        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Corte de cabelo
        </Typography>
        <Grid item>
          <Card variant="outlined">
            <Grid container>
              <Grid item xs={2} sx={{ backgroundColor: `rgb(${colorPrimary})`, color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Typography variant="h6" sx={{ writingMode: 'vertical-lr', textOrientation: 'mixed' }}>1h</Typography>
              </Grid>
              <Grid item xs={10} sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://img.freepik.com/fotos-gratis/cabeleireiro-feminino-fazendo-penteado-para-mulher-morena-no-salao-de-beleza_176420-4465.jpg"
                  alt="Imagem"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <div style={{ height: "10px" }}></div>
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
          <b style={{ float: "left", paddingTop: "8px", paddingLeft: "5px" }}>R$ 15,00</b>
          <Button style={{ float: "right", color: 'white', backgroundColor: `rgb(${colorPrimary})` }} onClick={() => setOpen(true)}>Ver detalhes</Button>
        </Typography>
        <div style={{ height: "60px" }}></div>
        <Divider />
        <div style={{ height: "20px" }}></div>

        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Corte de cabelo
        </Typography>
        <Grid item>
          <Card variant="outlined">
            <Grid container>
              <Grid item xs={2} sx={{ backgroundColor: `rgb(${colorPrimary})`, color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Typography variant="h6" sx={{ writingMode: 'vertical-lr', textOrientation: 'mixed' }}>1h</Typography>
              </Grid>
              <Grid item xs={10} sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://img.freepik.com/fotos-gratis/cabeleireiro-feminino-fazendo-penteado-para-mulher-morena-no-salao-de-beleza_176420-4465.jpg"
                  alt="Imagem"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <div style={{ height: "10px" }}></div>
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
          <b style={{ float: "left", paddingTop: "8px", paddingLeft: "5px" }}>R$ 15,00</b>
          <Button style={{ float: "right", color: 'white', backgroundColor: `rgb(${colorPrimary})` }}>Ver detalhes</Button>
        </Typography>
        <div style={{ height: "60px" }}></div>
        <Divider />


        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >

          <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>


          <Typography style={{ fontSize: "20px", fontWeight: "bold", paddingLeft: "16px" }}>
            Corte de cabelo
          </Typography>
          <Typography style={{ fontSize: "14px", paddingLeft: "16px", color: "#94a3b8" }}>
            Corte de cabelo do rafa, raspa em cima e pica atrás como ele gosta.
          </Typography>
          <div style={{ height: "20px" }}></div>
          <Typography style={{ fontSize: "14px", paddingLeft: "16px", color: "#94a3b8" }}>
            <b>Tempo de serviço: 1h</b><br />
            <div style={{ height: "20px" }}></div>
            <b>Observação: Chegar com pelo menos 15 minutos de antecedencia</b>
            <div style={{ height: "60px" }}></div>
          </Typography>
          <Typography style={{ fontSize: "20px", fontWeight: "bold", paddingLeft: "16px" }}>
            R$ 15,00
            <Button style={{ float: "right", color: 'white', backgroundColor: `rgb(${colorPrimary})`, marginRight: "16px" }} onClick={handleAgendar}>Agendar</Button>
          </Typography>
          <div style={{ height: "20px" }}></div>
        </SwipeableDrawer>
      </Container>
    </ThemeProvider>
  );
}

export default NovoAgendamento;