import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, Divider } from '@mui/material';
import { AppShortcutOutlined, ArrowBackOutlined, ArrowForwardIos, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import * as React from 'react';

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

function EscolherProfissional() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/novo-agendamento');
  };

  const handleHorarios = () => {
    navigate('/horarios');
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>

        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Profissional
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Escolha seu profissional
        </Typography>

        <div style={{ height: "10px" }}></div>

        <Grid item xs={12} md={6}>
          <List dense={dense}>
            <ListItem
              disableGutters
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={handleHorarios}>
                  <ArrowForwardIos />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar src={"https://negociosdebeleza.beautyfair.com.br/wp-content/uploads/2022/12/cabeleireira.png"} style={{ width: "50px", height: "50px" }} />

              </ListItemAvatar>
              <ListItemText
                primary={<Typography style={{ paddingLeft: "5px", fontWeight: "bold" }}>Victoria Carvalho</Typography>}
              />

            </ListItem>
            <Divider />
            <div style={{ height: "10px" }}></div>
            <ListItem
              disableGutters
              secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <ArrowForwardIos />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar src={"https://img.clasf.com.br/2023/01/01/Salo-de-Beleza-Mangabeira-20230101191519.7893470015.jpg"} style={{ width: "50px", height: "50px" }} />

              </ListItemAvatar>
              <ListItemText
                primary={<Typography style={{ paddingLeft: "5px", fontWeight: "bold" }}>Rafaela Esteves</Typography>}
              />

            </ListItem>
            <Divider />


          </List>
        </Grid>

      </Container>
    </ThemeProvider>
  );
}

export default EscolherProfissional;